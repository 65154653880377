import React from "react";
import listIcon from "../../pages/digitalMarketing/img/listIcon.svg";

export default function AnclaList(props) {
  return (
    <li className="mt-2" {...props}>
      <p className="section-description flex items-center">
        <span>
          <img src={listIcon} alt="" />
        </span>
        {props.children}
      </p>
    </li>
  );
}
