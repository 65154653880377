import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";

export default function DataAnalysis() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Data Analysis",
    sectionHeaderDescription: "Turn Big Data into Intelligence and actionable insight",
    sectionDescription: [
      "Big data unlocks hidden opportunities and insights. AnclaTechs turn technology into business outcomes by delivering information management, business intelligence and analytic solutions under one umbrella.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <div class="w-full md:w-3/5 mx-left">
          {faqs.map((faq, key) => (
            <div
              //   key={key}
              className="tab w-full overflow-hidden border rounded-md mt-5"
            >
              <input className="absolute opacity-0 " id="tab-multi-one" type="checkbox" name="tabs" />
              <label className="block p-5 leading-normal cursor-pointer text-primary" htmlFor="tab-multi-one">
                {faq.questions}
              </label>
              <div className="tab-content overflow-hidden text-primary-300 text-h6 font-light bg-gray-100 leading-normal">
                <p className="p-5">{faq.answers}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const faqs = [
  {
    questions: "Big Data",
    answers: "Harness big data analytics to drive better business decisions",
  },
  {
    questions: "Data Frameworks",
    answers: "Data Frameworks",
  },
  {
    questions: "Data Visualization",
    answers: "Use advanced data visualization for better insight as compared to traditional enterprise reporting",
  },
  {
    questions: "Data Analytics",
    answers: "Build a data analytics foundation for a long term",
  },
  {
    questions: "Informed Intelligence",
    answers: "Apply decision science to resolve your business issues",
  },
  {
    questions: "Next-Gen Analytics",
    answers: "Apply decision science to resolve your business issues",
  },
  {
    questions: "Enterprise Data Management",
    answers: "Apply decision science to resolve your business issues",
  },
  {
    questions: "Business Intelligence",
    answers: "Apply decision science to resolve your business issues",
  },
];
