import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function QualityAssuranceProcess() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Quality Assurance Strategy & Process",
    sectionHeaderDescription: "Enable reliable, secure applications that deliver superior customer experience",
    sectionDescription: [
      "Multiple factors are forcing business and IT processes to transform at a rapid pace. The triggers vary from globalization, technology advancements to challenges in regulatory compliance and more. To keep pace with this transformation, organizations are under increasing pressure to ensure faster and better delivery of software and services to retain competitive edge. ",
      "Anclatechs consultants can help you build robust quality assurance strategies and processes. They will ensure that your IT applications are reliable, have enhanced security and at the same time deliver superior customer experience to your business.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <h5 className="text-primary text-h4">Our quality assurance sevices include:</h5>

        <div className="mt-18">
          <ol className="mt-5">
            {services.map((value, key) => (
              <AnclaList key={key}>{value}</AnclaList>
            ))}
          </ol>
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const services = [
  "Advisory services in terms of architecture, design and implementation of quality assurance",
  "Managed test services to ensure high quality, low cost and faster quality assurance",
  "Implementation of best practices in testing across Agile, DevOps and automation",
];
