import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";

export default function UserExperienceDesign() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "User Experience Design",
    sectionHeaderDescription: "Solve device complexity with simple design solutions for enhanced digital customer experience",
    sectionDescription: [
      "Today’s consumers expect a consistent user experience across every device they own. To develop a unified, friendly design, you need experts who understand your audience and can design an easy-to-use interface with cross-platform consistency to improve the overall digital customer experience",
      "AnclaTechs’ digital customer experience services achieve that fine balance between form, function, and usability. And, because our engineers come from leading design schools around the globe, they understand the importance of regional preferences while retaining elegance and site stickiness.",
      "AnclaTechs’ unique digital innovation hub provides an interactive space where multi-disciplinary teams come together to ideate, design and craft meaningful digital experiences for organizations.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-20">
          {services.map((service) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{service.title}</h5>

              <p className="section-description ">{service.description}</p>
            </div>
          ))}
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const services = [
  {
    title: "Cross Channel User Experience Design",
    description:
      "Our team of seasoned user experience designers incorporate context and human factors in end user design. Consumer lifestyle factors and touch points are carefully considered in the seamless, connected experience.",
  },
  {
    title: "Unified Enterprise Experience Design",
    description:
      "Enterprise design ensures interoperability. Anclatech’s proven digital user experience approach systematically integrates user roles, business transactions and processes to create a coherent, collaborative experience across applications.",
  },
  {
    title: "Mobility Experience Design",
    description:
      "Our user experience designers create compelling, interactive experiences for consumer and business applications, mobile sites, games and more. They carefully consider application needs and context when developing features and content to ensure a seamless mobile user-centric experience.",
  },
  {
    title: "Data Visualization",
    description:
      "Anclatech’s user experience design team works to understand your end-user information needs, priorities, access frequency, structure, and hierarchy models. The team, then combines this knowledge with solid data visualization and information design principles to create interactive displays. The interactive displays, bring clarity and precision to business processes and decision making",
  },
];
