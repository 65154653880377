import React from "react";
import Layout from "../../components/Layout";
import Section from "../../components/section/Section";
import Support from "../../pages/home/img/Support.svg";
import SectionInfo from "../../components/sectionInfo/SectionInfo";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useUpdatePostMutation } from "../../components/sectionContact/SectionSliceApi";
import { useSnackbar } from "notistack";
const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  company_name: yup.string().required(),

  work_email: yup.string().required(),
  role: yup.string().required(),
  solution_type: yup.array().required(),
  industry: yup.string().required(),
  projected_numbers: yup.string().required(),
  proposed_project: yup.string().required(),
});
export default function ContactUs(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  const [updatePost] = useUpdatePostMutation();

  async function onSubmit(data) {
    updatePost({ ...data })
      .then(() => {
        enqueueSnackbar("Successfully Submitted", {
          variant: "success",
        });
        reset();
      })
      .catch(() =>
        enqueueSnackbar("Failed to Submit, please try again", {
          variant: "error",
        })
      );
  }

  return (
    <Layout
      pageMeta={{
        title: "Contact Us",
        author: "",
        image: Support,
        description: "Ancla Technologies is a leading software development company for enterprise solutions in Africa. Gear your business into automation today",
      }}
    >
      <Section className="flex flex-wrap justify-between">
        <SectionInfo>
          <div>
            <img src={Support} alt="Digital Officer" className="w-full" style={{ maxWidth: "450px" }} />
          </div>
        </SectionInfo>
        <SectionInfo>
          <SectionTitle>Let’s talk about your needs</SectionTitle>
          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            {/* first and last name */}
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block">
                  <span className="text-xs font-light text-primary-300">First Name </span>
                  <input
                    class="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    classname="form-input mt-1 block w-full"
                    placeholder="John"
                    {...register("first_name", { required: true })}
                  />
                  <small style={{ color: "red" }}>{errors.first_name?.message}</small>
                </label>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block">
                  <span className="text-xs font-light text-primary-300">Last Name </span>
                  <input
                    class="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    classname="form-input mt-1 block w-full"
                    placeholder="Doe"
                    {...register("last_name", { required: true })}
                  />
                  <small style={{ color: "red" }}>{errors.last_name?.message}</small>
                </label>
              </div>
            </div>
            {/* End first and last name */}

            {/* work Email and company name */}
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block">
                  <span className="text-xs font-medium text-primary-200">Work Email </span>
                  <input
                    class="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="email"
                    classname="form-input mt-1 block w-full"
                    placeholder="Johndoe@mail.com"
                    {...register("work_email", { required: true })}
                  />
                  <small style={{ color: "red" }}>{errors.work_email?.message}</small>
                </label>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label className="block">
                  <span className="text-xs font-light text-primary-300">Company Name </span>
                  <input
                    class="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    classname="form-input mt-1 block w-full"
                    placeholder="Doe"
                    {...register("company_name", { required: true })}
                  />
                  <small style={{ color: "red" }}>{errors.company_name?.message}</small>
                </label>
              </div>
            </div>
            {/* End first and last name */}

            {/* Industry input */}
            <label className="block mt-8">
              <span className="text-xs font-light text-primary-300">What role best describes you? </span>
              <select
                {...register("role", { required: true })}
                className="form-select block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option selected disabled>
                  Select role
                </option>
                {role.map((industry, key) => (
                  <option value={industry.value}>{industry.label}</option>
                ))}
              </select>
              <small style={{ color: "red" }}>{errors.role?.message}</small>
            </label>
            {/* End Industry input */}

            {/* Soluion Type */}
            <label class="block mt-8">
              <span className="text-xs font-light text-primary-300">Preferred solution type </span>
              <select
                {...register("solution_type", { required: true })}
                class="form-multiselect block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                multiple
              >
                {prefferedSolutions.map((industry, key) => (
                  <option value={industry.value}>{industry.label}</option>
                ))}
              </select>
              <small style={{ color: "red" }}>{errors.solution_type?.message}</small>
            </label>
            {/* End solution Type */}

            {/* Industry input */}
            <label className="block mt-8">
              <span className="text-xs font-light text-primary-300">Industry (Category) </span>
              <select
                {...register("industry", { required: true })}
                className="form-select block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option selected disabled>
                  Select Industry
                </option>
                {industries.map((industry, key) => (
                  <option value={industry.value}>{industry.label}</option>
                ))}
              </select>
              <small style={{ color: "red" }}>{errors.industry?.message}</small>
            </label>
            {/* End Industry input */}

            {/* Projected Number input */}
            <label className="block mt-8">
              <span className="text-xs font-light text-primary-300">Projected number of daily users on the e-Platform solution </span>
              <select
                {...register("projected_numbers", { required: true })}
                className="form-select block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option selected disabled>
                  Select Projected number of daily users
                </option>
                {projectedNumber.map((industry, key) => (
                  <option value={industry.value}>{industry.label}</option>
                ))}
              </select>

              <small style={{ color: "red" }}>{errors.projected_numbers?.message}</small>
            </label>
            {/* Projected Number  input */}

            {/*Short description of proposed project input */}
            <div className="w-full  mt-8">
              <label className="block">
                <span className="text-xs font-light text-primary-300">Short description of proposed project </span>
                <textarea
                  {...register("proposed_project", { required: true })}
                  class="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  rows={5}
                  cols={5}
                  classname="form-input mt-1 block w-full"
                  // placeholder="Social media"
                />
                <small style={{ color: "red" }}>{errors.proposed_project?.message}</small>
              </label>
            </div>
            {/* End Short description of proposed project input */}

            <button type="submit" class="bg-secondary-100 w-full text-primary rounded-lg text-h6 hover:bg-secondary-200 px-4 py-3 mt-10 focus:outline-none">
              Submit
            </button>
          </form>
        </SectionInfo>
      </Section>

      <Section>
        <div className="flex flex-wrap justify-between">
          {contacts.map((contact) => (
            <div class="mt-2">
              <span class="material-icons-outlined text-secondary-100 material-icon-after relative" style={{ fontSize: "2.5rem" }}>
                {contact.icon}
              </span>
              <h5 className="text-primary text-h5 font-normal">{contact.title}</h5>
              {contact.description.map((value) => (
                <p className="text-primary-300 text-h6 font-light">{value}</p>
              ))}
            </div>
          ))}
        </div>
      </Section>
    </Layout>
  );
}

const contacts = [
  {
    icon: "location_on",
    title: "Address",
    description: ["3 Alara Street, Sabo-Yaba, Lagos,Nigeria", "Kahawa Magunas off ThikaRoad Nairobi, Kenya"],
  },
  { icon: "mail", title: "Email", description: ["sales@anclatechs.com"] },
  {
    icon: "call",
    title: "Phone",
    description: ["+234 816 235 7100", "+254 745 146 342"],
  },
];

const industries = [
  { label: "Education", value: "Education" },
  { label: "Health", value: "Health" },
  {
    label: "Banking, Investments and Finance",
    value: "Banking, Investments and Finance",
  },
  { label: "Religious Institutes", value: "Religious Institutes" },
  { label: "Technology", value: "Technology" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Sales & Marketing", value: "Sales & Marketing" },
  { label: "Other", value: "Other" },
];

const projectedNumber = [
  { label: "Less than 500 daily users", value: "Less than 500 daily users" },
  { label: "500 - 1000 daily users", value: "500 - 1000 daily users" },
  {
    label: "1000 - 10,000 daily users",
    value: "1000 - 10,000 daily users",
  },
  {
    label: "10,000 - 50,000 daily users",
    value: "10,000 - 50,000 daily users",
  },
  {
    label: "50,000 - 100,000 daily users",
    value: "50,000 - 100,000 daily users",
  },
  { label: "above 100,000 daily users", value: "above 100,000 daily users" },
  { label: "Not applicable", value: "Not applicable" },
];

const prefferedSolutions = [
  { label: "Web application", value: "Web application" },
  { label: "Mobile application", value: "Mobile application" },
  {
    label: "Desktop application",
    value: "Desktop application",
  },
  { label: "Consulting services", value: "Consulting services" },
];

const role = [
  { label: "Business owner", value: "Business owner" },
  { label: "Chief Executive Officer", value: "Chief Executive Officer" },
  {
    label: "Chief Operating Officer",
    value: "Chief Operating Officer",
  },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Freelancer", value: "Freelancer" },
  {
    label: "Product Engineer/Developer/Designer",
    value: "Product Engineer/Developer/Designer",
  },
];
