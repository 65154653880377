import React from "react";
import Layout from "../../components/Layout";
import Section from "../../components/section/Section";
import { NavLink, Link } from "react-router-dom";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import { useGetBlogBySlugQuery, useGetCategoryListQuery, useGetRelatedBlogBySlugQuery } from "../../pages/blog/BlogSliceApi";
import ContentLoader from "react-content-loader";
import { RouteEnum } from "../../utils/constants";

export default function BlogDetail(props) {
  const { match } = props;
  const { data, error, isLoading } = useGetBlogBySlugQuery(React.useMemo(() => match.params.slug, [match.params.slug]));

  const { data: relatedData, error: relatedError, isLoading: relatedIsLoading } = useGetRelatedBlogBySlugQuery(React.useMemo(() => match.params.slug, [match.params.slug]));

  const { data: categoryData, error: categoryError, isLoading: categoryIsLoading } = useGetCategoryListQuery();

  return (
    <Layout
      pageMeta={{
        title: `${data?.title}`,
        author: `${data?.author}`,
        image: `${data?.image}`,
        description: `${data?.content}`,
      }}
    >
      <Section>
        {error ? (
          <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
            <p class="font-bold">Something went wrong</p>
            <p>Oh no, there was an error, please reload</p>
          </div>
        ) : isLoading ? (
          <ContentLoader />
        ) : data ? (
          <>
            <SectionTitle className="text-center capitalize">{data?.title}</SectionTitle>

            <div className="flex flex-wrap">
              <div className="w-full md:w-3/5">
                <div className="mt-5">
                  <div className="mt-10 mb-18">
                    <div className="">
                      <div
                        style={{
                          backgroundImage: `url(${data?.image})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                        className=" w-full h-96 block"
                      ></div>
                    </div>

                    <div className="my-20">
                      <div className="text-primary-300  ">
                        <div className="formated-content" dangerouslySetInnerHTML={{ __html: data?.content }} />
                      </div>
                    </div>

                    <div className="mb-5">
                      {data?.tag.map((value) => (
                        <Link to="/">
                          <span class="inline-flex mx-1 items-center justify-center px-2 py-1 text-xs font-light leading-none  border border-grey-300 hover:border-grey-500 rounded">
                            {value}
                          </span>
                        </Link>
                      ))}
                    </div>

                    <hr></hr>
                    <div className="mt-10 ">
                      <h5 className="text-primary-200">Written By</h5>
                      <a href={data?.social_twitter} target="_blank" rel="noreferrer">
                        <h3 className="capitalize text-h5 text-primary">{data?.author}</h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-2/5">
                <div className="mt-10 px-2">
                  <div className="shadow flex">
                    <input className="w-full rounded p-2" type="text" placeholder="Search keywords" />
                    <button className="bg-white w-auto flex justify-end items-center text-primary p-2 hover:text-primary-400">
                      <i class="material-icons">search</i>
                    </button>
                  </div>
                </div>

                <div className="mt-10 px-5">
                  <h3 className="text-primary font-semibold">Categories</h3>
                  {categoryError && <p className="text-tertiary-200">Error loading Category</p>}
                  {categoryIsLoading && <p>Category is Loading...</p>}

                  {categoryData &&
                    categoryData?.results.map((data) => (
                      <NavLink to={`${RouteEnum.BLOG_CATEGORY_ROUTE}/${data?.name}`} className="capitalize">
                        {" "}
                        <p className="capitalize text-primary hover:text-tertiary-200 py-3 cursor-pointer  border-b-2">{data.name}</p>
                      </NavLink>
                    ))}
                </div>

                <div className="mt-10 px-5">
                  <h3 className="text-primary font-semibold">Next Posts</h3>
                  {error && <p className="text-tertiary-200">Error loading Related Post</p>}
                  {isLoading && <p>Related Post is Loading...</p>}

                  {data && (
                    <NavLink to={`${RouteEnum.BLOG}/${data?.next_post?.slug}`} className="capitalize">
                      <div className="flex">
                        <img
                          style={{
                            maxWidth: "100px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          src={`${data?.next_post?.image}`}
                          alt={data?.next_post?.title}
                        />
                        <div className="pl-1 flex flex-col justify-between">
                          <h5 className="text-primary">{data?.next_post?.title}</h5>
                          <div className="text-primary-200 mt-1" style={{ fontSize: ".78rem" }}>
                            <div className="flex items-center">
                              {" "}
                              <span style={{ fontSize: ".78rem" }} class="material-icons-outlined mr-1">
                                calendar_today
                              </span>
                              {data?.next_post?.publish}
                            </div>
                            {/* <div className="flex capitalize items-center">
                              <span
                                style={{ fontSize: ".78rem" }}
                                class="material-icons-outlined mr-1"
                              >
                                person
                              </span>
                              {data?.next_post?.author}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Section>
      <Section>
        <hr></hr>

        <div className="mt-10">
          <h3 className="text-primary font-semibold text-h5">Related Posts</h3>
          {relatedError && <p className="text-tertiary-200">Error loading Related Post</p>}
          {relatedIsLoading && <p>Related Post is Loading...</p>}

          <div class="flex flex-wrap">
            {relatedData &&
              relatedData?.related_post?.map((data) => (
                <NavLink to={`${RouteEnum.BLOG}/${data.slug}`} className="capitalize md:w-1/3 w-full py-5 px-2">
                  <div>
                    <img
                      style={{
                        // maxWidth: "100px",
                        maxHeight: "200px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={`${data.image}`}
                      alt={data?.title}
                    />
                    <div className="pl-1">
                      <h3 className="text-primary text-h6 mt-2">{data.title}</h3>
                      <div className="text-primary-200 mt-3" style={{ fontSize: ".78rem" }}>
                        <div className="flex items-center">
                          {" "}
                          <span style={{ fontSize: ".78rem" }} class="material-icons-outlined mr-2">
                            calendar_today
                          </span>
                          {data.publish}
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              ))}
          </div>
        </div>
      </Section>
    </Layout>
  );
}
