import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function ComputerTrainingScholarshipProgramme() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Computer Training Scholarship Programme",
    sectionHeaderDescription: "Microlearning: Geared for the Modern Learner and Designed for Business Results",
    sectionDescription: [
      `Importance of employee training cannot be overemphasized. It is a strategic instrument for boosting employees' performance
      and organization's growth. In recent years, employees' training and development have emerged as a crucial element of strategy. More company owners have realized that investing in employee training and development not only serves as a motivation, but it also enables the organization to create a highly skilled workforce.
      `,
      "Our research also indicated that organisations embracing employee training have more adept, skillful, longer serving workforce than businesses who do not.",
      "Employee stabiliy being crucial for improved productive and economic growth of any business; AnclaTechs through it Education Arm (GoLab) provides tailored courses, IT orientation for our partnered firms' workforce",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <SectionTitle>
          <h3 className="text-primary text-h5">Employee Training Courses (GoLab Institute of Technology)</h3>{" "}
        </SectionTitle>
        <div className="mt-5">
          <h5 className="section-description  mt-3 pb-5">
            <ul>
              {employeeTrainingCourses.map((course) => (
                <AnclaList>{course}</AnclaList>
              ))}
            </ul>
          </h5>
        </div>
      </Section>

      <Section>
        <SectionTitle>
          <h3 className="text-primary text-h5">List of other Courses (GoLab Institute of Technology)</h3>{" "}
        </SectionTitle>
        <div className="mt-5">
          <h5 className="section-description  mt-3 pb-5">
            <ul>
              {courses.map((course) => (
                <AnclaList>{course}</AnclaList>
              ))}
            </ul>
          </h5>
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const employeeTrainingCourses = ["General Computer Training", "Microsoft Office - Word, Excel & PowerPoint"];
const courses = ["Data Science", "Frontend Development", "Backend Development", "UI/UX Design"];
