import clsx from "clsx";
import React from "react";

/**
 *
 * @param {React.ComponentPropsWithoutRef<'section'>} props
 */
function Section(props) {
  const { className, ...rest } = props;
  return (
    <section
      className={clsx(
        "container max-w-screen-ac mx-auto py-20 px-5",
        className
      )}
      {...rest}
    />
  );
}

export default Section;
