import Section from "../../components/section/Section";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import React from "react";
import { NavLink } from "react-router-dom";
import { RouteEnum } from "../../utils/constants";

export default function SectionContactForm() {
  return (
    <div className="bg-primary">
      <Section>
        <SectionTitle white className="text-center mb-10">
          How we could be of help to you
        </SectionTitle>
        <div className="flex justify-center">
          <form type="submit" className="max-w-2xl">
            <input class="mt-5 w-full border bg-white rounded-lg text-primary px-3 py-5 outline-none" disabled value="What is your Organisation’s greatest Challenge?"></input>
            <NavLink to={`${RouteEnum.CONTACT_US}`}>
              <button type="button" class="bg-secondary-100 w-full text-primary rounded-lg text-h6 hover:bg-secondary-200 px-4 py-4 mt-5 focus:outline-none">
                Find A Solution
              </button>
            </NavLink>
          </form>
        </div>
      </Section>
    </div>
  );
}
