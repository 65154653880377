import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function Agile() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Agile",
    sectionHeaderDescription: "Transform into and agile enterprise to deliver exceptional experiences",
    sectionDescription: [
      "Customer experience is the benchmark of success for companies across industries. And it is becoming increasingly important to have robust software development processes in place to deliver superior customer experiences and enable digital transformation. ",
      "Agile software development methodology helps deliver high-quality applications to meet those customer demands. ",
      "However, clients often struggle when choosing a speciﬁc adoption path, so enterprises need the right partner to help them choose the best approach and deliver projects efficiently.Customer experience is the benchmark of success for companies across industries. And it is becoming increasingly important to have robust software development processes in place to deliver superior customer experiences and enable digital transformation.",
      " Agile software development methodology helps deliver high-quality applications to meet those customer demands.",
      "However, clients often struggle when choosing a speciﬁc adoption path, so enterprises need the right partner to help them choose the best approach and deliver projects efficiently.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <SectionTitle>Anclatechs, with our born-digital expertise,</SectionTitle>
        <div className="mt-5">
          {expertise.map((value) => (
            <h5 className="section-description  mt-3 pb-5">{value}</h5>
          ))}
        </div>
      </Section>

      <Section>
        <h3 className="text-primary text-h5">Maintenance and operation of all digital marketing solutions </h3>
        <div className="mt-8">
          <ul>
            {marketingSolutions.map((solution) => (
              <AnclaList>{solution}</AnclaList>
            ))}
          </ul>
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const marketingSolutions = ["Agile digital", "Agile digital", "Agile consulting", "Agile development pods", "Continuous delivery transformation"];

const expertise = [
  "Our approach is based on industry-standard Agile methodologies and strikes a balance between engineering and project management. Our best practices and governance models foster communication, coordination and collaboration among distributed teams, and we collaborate with our clients to understand their past experience and develop future road maps. ",
  "Whether an organization needs a Certified Scrum Coach for a single pod or a SAFe Program Consultant to scale Agile across the entire organization, our experts can help. Using tools like our proprietary Agile Assessment app, our coaches offer multidimensional analysis of an enterprise’s Agile maturity and a clear road map to enhance and expedite its journey. ",
  "We work support our clients with strategic recommendations for adopting Agile methodology. We help you choose platforms, tools and metrics that increase visibility and predictability, and we create playbooks, share best practices and tailor processes so your organization can truly become Agile.",
];
