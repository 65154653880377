import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";

export default function Devops() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "DevOps Services and Solutions",
    sectionHeaderDescription: "Create, connect and orchestrate digital components",
    sectionDescription: [
      "The digital tidal wave has spurred enterprises to bring development and operations out of their siloes and into alignment with the rest of the organization. Now, DevOps, is one of the keys to making IT a relevant business partner. ",
      "There has always been a wall between application developers and IT operations, and it has prevented true collaboration. DevOps helps tear down that wall.",
      "Disruptive trends, like mobility, big data, cloud and social are driving demand for high-quality apps and services. And the primary goal of DevOps is bridging the gap between development and operations to enable faster, more efficient production. It works by making changes on the go to production software (continuous integration) instead of in a test environment.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <SectionTitle>
          <h3 className="text-primary text-h5">What can DevOps do for you? </h3>{" "}
        </SectionTitle>
        <div className="mt-5">
          {expertise.map((value) => (
            <h5 className="section-description  mt-3 pb-5">{value}</h5>
          ))}
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const expertise = [
  "The benefits of DevOps can span the entire delivery pipeline. It can help you improve deployment frequency, leading to faster time to market, lower failure rate of new releases, shortened lead time between ﬁxes and faster time to recovery.",
  "By embracing a DevOps approach, your organization can make simple processes programmable and dynamic, helping to maximize operational predictability, efficiency, security and maintainability. ",
  "As a digital-first organization, AnclaTechs offers unique differentiators to support you as you to embark on your DevOps journey.",
];
