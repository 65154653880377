import React from "react";
import ButtonBase from "./ButtonBase";
import clsx from "clsx";

/**
 *
 * @param {ButtonProps} props
 */
export default function Button(props) {
  const { variant, className, bgColor, color, ...rest } = props;

  return (
    <ButtonBase
      {...rest}
      className={clsx(
        "inline-flex py-2 relative buttonLink px-5 text-primary rounded",
        {
          [`${bgColor}`]: variant === "contained",
          "ring-1": variant === "outlined",
          "": variant === "text",
        },
        className,
        color
      )}
    />
  );
}
