import React from "react";

export default function ScrollToTop() {
  return (
    <div>
      <button
        onClick={() => window.scrollTo(0, 0)}
        className="fixed bottom-0 left-0 mx-5 my-12 bg-secondary-100 shadow z-20 hover:bg-secondary-200 color-primary py-2 px-3 rounded-full text-white"
      >
        <span class="material-icons">keyboard_arrow_up</span>
      </button>
    </div>
  );
}
