import Section from "../../components/section/Section";
import SectionDescription from "../../components/sectionDescription/SectionDescription";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import React from "react";
import Layout from "../../components/Layout";
import SectionInfo from "../../components/sectionInfo/SectionInfo";
import HomeMascot from "./img/HomeMascot.svg";
import DigitalOfficer from "./img/DigitalOfficer.png";
import Button from "../../components/button/Button";
import DigitalPC from "./img/DigitalPC.svg";
import IconSquare from "./img/IconSquare.svg";
import IconStar from "./img/IconStar.svg";
import Lines from "./img/Lines.svg";
import IconCircleGold from "./img/IconCircleGold.svg";

import { BoxType } from "../../utils/constants";
import SectionContactSupport from "../../components/sectionContact/SectionContactSupport";
import HomePartnersSliders from "./HomePartnersSliders";
import { NavLink } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { usePostSubscriptionMutation } from "../../components/sectionContact/SectionSliceApi";
import { useSnackbar } from "notistack";
import { useGetFeaturedListQuery } from "../../pages/blog/BlogSliceApi";
import ContentLoader from "react-content-loader";
import { RouteEnum } from "../../utils/constants";

const schema = yup.object().shape({
  email: yup.string().required(),
});

function Home(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  const [postSubscription] = usePostSubscriptionMutation();
  const { data, error, isLoading } = useGetFeaturedListQuery();

  async function onSubmit(data) {
    postSubscription({ ...data })
      .then(() => {
        enqueueSnackbar("Successfully subscribed to our Newsletter", {
          variant: "success",
        });
        reset();
      })
      .catch(() =>
        enqueueSnackbar("Failed to Submit to our newsletter, please try again", {
          variant: "error",
        })
      );
  }
  return (
    <Layout
      pageMeta={{
        title: "Home",
        author: "",
        image: "https://res.cloudinary.com/silva/image/upload/v1627755729/anclatech/logo.png",
        description: "Ancla Technologies is a leading software development company for enterprise solutions in Africa. Gear your business into automation today",
      }}
    >
      <Section className="flex flex-wrap min-h-screen items-center">
        <SectionInfo>
          <div>
            <SectionTitle data-aos="fade-down" data-aos-easing="linear" header>
              Welcome to Possibilities
            </SectionTitle>
            <SectionDescription data-aos="fade-down" data-aos-easing="linear" data-aos-once="true" data-aos-duration="1500" className="mt-14 max-w-md">
              Ancla Technologies; a company with the long term vision to transform the way you work
            </SectionDescription>
            <img
              data-aos="fade-left"
              data-aos-easing="linear"
              className="absolute hidden  md:block left-0 transform -translate-x-1/2"
              src={Lines}
              alt="lines"
              style={{ maxWidth: "500px" }}
            />
          </div>
        </SectionInfo>
        <SectionInfo className="flex justify-center">
          <img src={HomeMascot} alt="mascot" data-aos="fade-left" data-aos-once="true" className="w-full" style={{ maxWidth: "350px" }} />
        </SectionInfo>

        <div onClick={() => window.scrollTo(0, 800)} className="ico animated cursor-pointer">
          <div className="circle circle-top" />
          <div className="circle circle-main" />
          <div className="circle circle-bottom" />
          <svg
            className="svg"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 612 612"
            style={{ enableBackground: "new 0 0 612 612" }}
            xmlSpace="preserve"
          >
            <defs>
              <clipPath id="cut-off-arrow">
                <circle cx={306} cy={306} r={287} />
              </clipPath>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation={10} result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
            <path
              className="st-arrow"
              d="M317.5,487.6c0.3-0.3,0.4-0.7,0.7-1.1l112.6-112.6c6.3-6.3,6.3-16.5,0-22.7c-6.3-6.3-16.5-6.3-22.7,0
    l-86,86V136.1c0-8.9-7.3-16.2-16.2-16.2c-8.9,0-16.2,7.3-16.2,16.2v301.1l-86-86c-6.3-6.3-16.5-6.3-22.7,0
    c-6.3,6.3-6.3,16.5,0,22.7l112.7,112.7c0.3,0.3,0.4,0.7,0.7,1c0.5,0.5,1.2,0.5,1.7,0.9c1.7,1.4,3.6,2.3,5.6,2.9
    c0.8,0.2,1.5,0.4,2.3,0.4C308.8,492.6,313.8,491.3,317.5,487.6z"
            />
          </svg>
        </div>
      </Section>

      <Section className="flex relative flex-wrap min-h-screen items-center">
        <SectionInfo>
          <div data-aos="fade-right" data-aos-once="true">
            {isLoading && <ContentLoader />}
            {data && (
              <>
                <SectionTitle>{data?.results[0]?.title ? data?.results[0]?.title : "Chief Digital Officer: A Champion of Change"}</SectionTitle>
                <SectionDescription className="my-14">
                  {data?.results[0]?.short_description
                    ? data?.results[0]?.short_description
                    : "View the surprising result revealed in survey to better understanding this unique role"}
                </SectionDescription>
                {data?.results[0]?.slug ? (
                  <NavLink to={`${RouteEnum.BLOG}/${data?.results[0]?.slug}`}>
                    <Button className="mt-10">Learn More</Button>
                  </NavLink>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </SectionInfo>
        <SectionInfo className="flex justify-right">
          <div>
            <img src={DigitalOfficer} alt="Digital Officer" className="w-full" data-aos="fade-left" data-aos-once="true" style={{ maxWidth: "450px" }} />
          </div>
        </SectionInfo>
      </Section>

      <Section className="flex relative flex-wrap min-h-screen items-center">
        <img src={IconSquare} alt="" className="absolute top-0 right-0" />

        <SectionInfo className="flex justify-center">
          <div>
            <img src={DigitalPC} alt="mascot" data-aos="fade-right" data-aos-once="true" className="w-full" style={{ maxWidth: "450px" }} />
          </div>
        </SectionInfo>
        <SectionInfo>
          <div data-aos="fade-left" data-aos-once="true">
            {isLoading && <ContentLoader />}
            {data && (
              <>
                <SectionTitle>{data?.results[1]?.title ? data?.results[1]?.title : "A New Era of Possibilities"}</SectionTitle>
                <SectionDescription className="my-14">
                  {data?.results[1]?.short_description
                    ? data?.results[1]?.short_description
                    : "Anclatechs is proud to now be part of Ancla Technologies a 200 Billion Naira IT giant. With the support of Ancla Technologies as its parent compant, Anclatechs is in an even"}
                </SectionDescription>
                {/* <NavLink to={`${RouteEnum.BLOG}/${data.slug}`}>
                  <Button className="mt-10">Learn More</Button>
                </NavLink> */}
                {data?.results[1]?.slug ? (
                  <NavLink to={`${RouteEnum.BLOG}/${data?.results[1]?.slug}`}>
                    <Button className="mt-10">Learn More</Button>
                  </NavLink>
                ) : (
                  ""
                )}
              </>
            )}
            {error && <h3>Error Loading content...</h3>}
          </div>
        </SectionInfo>
      </Section>

      <Section className="flex flex-col relative justify-center min-h-screen items-center">
        <img src={IconCircleGold} alt="" className="absolute hidden  md:block  top-0 right-52" />
        <img src={IconCircleGold} alt="" className="absolute hidden  md:block  top-32 right-0" style={{ maxWidth: "30px" }} />

        <div className="flex justify-center w-full">
          <div>
            <SectionTitle className="text-center">Services</SectionTitle>
            <p className="text-primary-300 mb-4 text-h6 font-extralight mt-8 text-center max-w-3xl">
              We offer customized digital services and scalable operational strategies to prepare your Organization ride through the waves of uncertainty
            </p>
          </div>
        </div>

        <div>
          <div className="max-w-4xl">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pt-10 relative">
              {/* <img src={IconCircle} alt="" className="absolute top-0 right-0" /> */}

              {services.map((service) => (
                <div
                  className={`shadow-md rounded-3xl py-16 px-10 flex items-center ${BoxType.BLUE === service.boxType && "services-blue"} ${
                    BoxType.BORDER === service.boxType && "services-border"
                  }`}
                >
                  <div>
                    <h5 className={` ${BoxType.BLUE === service.boxType ? "services-blue" : "text-primary "} text-h6 font-semibold`}>{service.title}</h5>
                    <p className={`${BoxType.BLUE === service.boxType ? "services-blue" : "text-primary-300"} mt-3 text-h7 font-light`}>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>

      <HomePartnersSliders />

      <div className="bg-primary">
        <Section>
          <SectionTitle white>Why work with Us?</SectionTitle>
          <div className="flex flex-col md:flex-row  justify-between mt-20">
            {workWithUs.map((work) => (
              <div className="max-w-md mt-8">
                <h5 className="text-white text-h5" style={{ maxWidth: "200px" }}>
                  {work.title}
                </h5>

                <p
                  className="mt-4 text-primary-200
                   text-h7 font-light"
                  style={{ maxWidth: "300px" }}
                >
                  {work.description}
                </p>
              </div>
            ))}
          </div>
        </Section>
      </div>

      <SectionContactSupport />

      <Section>
        <div className="flex relative justify-center w-full max-h-96 items-center">
          <img src={IconSquare} style={{ zIndex: "-1" }} alt="" className="absolute top-0 right-0" />
          <img src={IconStar} alt="" className="absolute top-0 left-0" />

          <div>
            {" "}
            <SectionDescription className="mt-8 text-center text-secondary text-h5 max-w-3xl">Get offers and instant update </SectionDescription>
            <SectionTitle className="text-center  text-h6">Subscribe Now </SectionTitle>
            <form className="mt-20" onSubmit={handleSubmit(onSubmit)}>
              <div className="relative flex w-full flex-wrap items-stretch mb-3 ">
                <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-10 pl-4 md:pl-8 py-3 md:py-6">
                  <span style={{ fontSize: "1rem" }} class="material-icons-outlined  text-primary-200">
                    mail
                  </span>
                </span>
                <input
                  {...register("email", { required: true })}
                  type="text"
                  placeholder="Email Address"
                  className="px-5 w-full md:px-14 py-3 md:py-5 font-light placeholder-blueGray-300 rounded-b-full rounded-t-full text-blueGray-600 relative bg-white bg-white rounded text-h6 border border-blueGray-300 outline-none focus:outline-none focus:ring pl-10"
                />
                <span className="z-10 h-full leading-snug font-normal  text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-100 pr-5 r-0 right-0 py-2 md:py-4">
                  <button class="bg-primary absolute right-4 hover:bg-primary-300   text-white py-1 md:py-2 px-8 md:px-10 rounded-full" type="submit">
                    Join
                  </button>
                </span>
              </div>
              <small style={{ color: "red" }}>{errors.role?.email}</small>
            </form>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export default Home;

const services = [
  {
    title: "Data Analytics",
    description: "Turn big data into intellect and actionable insight",
    boxType: BoxType.DEFAULT,
  },
  {
    title: "Cloud",
    description: "Digital transformation with a cloud-first approach",
    boxType: BoxType.BLUE,
  },
  {
    title: "IT Outsourcing and Support",
    description: "Enhance productivity, save operating cost with out IT outsourcing and product support services",
    boxType: BoxType.DEFAULT,
  },
  {
    title: "Infrastructure Maintenance",
    description: "Optimize IT Infrastructure and performance for increased business agility",
    boxType: BoxType.BORDER,
  },
  {
    title: "App development & maintenance",
    description: "Eliminate down time and maximize benefit of critical applications",
    boxType: BoxType.DEFAULT,
  },
];

const workWithUs = [
  {
    title: "IT Consulting & Advisory",
    description: "We focus on advising clients and organisations on how best to use Information Technology(IT) in achieving",
  },
  {
    title: "We have a Talented Team",
    description: "Our Organisation boosts of high performing top talents available in the industry as a team. We love, we work",
  },
  {
    title: "We don't Compromise",
    description: "We really love what we do, and our work on every project truly reflects that.",
  },
];
