import React from "react";
import SectionDescription from "../../components/sectionDescription/SectionDescription";
import LogoIcon from "./img/LogoIcon.svg";
import FacebookLogo from "./img/FacebookLogo.svg";
import LinkedinLogo from "./img/LinkedinLogo.svg";
import InstagramLogo from "./img/InstagramLogo.svg";
import TwitterLogo from "./img/TwitterLogo.svg";
import { Link } from "react-router-dom";
import { RouteEnum } from "../../utils/constants";

export default function Footer() {
  return (
    <div className="bg-primary px-4 text-center">
      <div className="container mx-auto pt-20  px-4">
        <div>
          <img src={LogoIcon} alt="" className="max-w-xs m-auto" />

          <SectionDescription white className="max-w-md text-center m-auto">
            <div className="mt-10 text-h6">Anclatechs is registered trademark of Ancla Technologies Ltd. All rights reserved by anclatechnologies.com</div>
          </SectionDescription>
          <div className="max-w-xl">
            <div className="flex flex-col md:flex-row  justify-between mt-10 text-white"></div>
          </div>
        </div>

        <div>
          <div className="w-100 my-10 h-0" style={{ border: "1px solid #D9E0FA" }}></div>
        </div>
        <div className="flex justify-center ">
          <div className="w-full">
            <ul className="flex justify-center flex-wrap">
              {footerLinkMain.map((link) => (
                <li className="mx-3">
                  <Link to={link.path}>
                    <SectionDescription white>
                      <div className="text-h6">{link.title}</div>
                    </SectionDescription>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-wrap justify-center">
          {socialIcons.map((icon) => (
            <a href={icon.link} target="_blank" rel="noreferrer">
              <img src={icon.icon} alt={icon.label} className="mx-3" />
            </a>
          ))}
        </div>

        <div className="flex justify-between flex-col md:flex-row mt-20">
          <SectionDescription white>
            <p className="text-h6">Ancla Technologies Ltd @ {new Date().getFullYear()}</p>
          </SectionDescription>
          <SectionDescription white className="font-thin">
            <ul className="flex flex-row">
              {footerLinkMain2.map((link) => (
                <li className="mx-3">
                  <Link to={link.path}>
                    <SectionDescription white>
                      <p className="text-h6">{link.title}</p>
                    </SectionDescription>
                  </Link>
                </li>
              ))}
            </ul>
          </SectionDescription>
        </div>
      </div>
    </div>
  );
}

const footerLinkMain = [
  { title: "Home", path: "/" },
  { title: "Services", path: RouteEnum.AGILE },
  { title: "Industries", path: RouteEnum.HEALTH },
  { title: "Careers", path: RouteEnum.CAREERS },
  { title: "About", path: RouteEnum.ABOUT_US },
];

const footerLinkMain2 = [
  { title: "FAQ", path: RouteEnum.FAQ },
  { title: "Careers", path: RouteEnum.CAREERS },

  { title: "T&Cs", path: RouteEnum.TERMS_AND_CONDITION },
  { title: "Privacy", path: RouteEnum.PRIVACY },
];

const socialIcons = [
  {
    label: "facebook",
    icon: FacebookLogo,
    link: "https://web.facebook.com/anclaTechs/",
  },
  {
    label: "twitter",
    icon: TwitterLogo,
    link: "https://twitter.com/anclatechs",
  },
  {
    label: "Instagram",
    icon: InstagramLogo,
    link: "https://instagram.com/anclatechs",
  },
  {
    label: "Linkedin",
    icon: LinkedinLogo,
    link: "https://www.linkedin.com/company/anclatechs/",
  },
];
