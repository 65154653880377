import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionServiceStickyLinks from "../../../components/sectionServiceStickyLinks/SectionServiceStickyLinks";

export default function Cloud() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Cloud",
    sectionHeaderDescription: "Digital transformation with a cloud-first approach",
    sectionDescription: [
      "Anclatechs delivers a holistic approach to cloud transformation that accelerates a company’s move to digital business. From cloud advisory to cloud build, from cloud migration to next generation enterprise cloud governance, we can be your anchor partner.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      <SectionServiceStickyLinks />
      <Section>
        <SectionTitle>Anclatechs provides the following cloud services:</SectionTitle>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-20">
          {cloudServices.map((service) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{service.title}</h5>

              <p className="section-description ">{service.description}</p>

              <p className="mt-8">
                <Link
                  // to={service.learnMore}
                  className="section-description relative  learnMore"
                >
                  Learn More
                </Link>
              </p>
            </div>
          ))}
        </div>

        <h4 className="section-description mt-32 mb-10 max-w-3xl">
          While most enterprises today have some kind of cloud presence, few maximize the vast opportunities that cloud affords. The pressures are growing to accelerate
          company-wide cloud adoption or risk falling behind the competition. We help in the cloud adoption journey at <span className="text-primary font-medium">all stages:</span>
        </h4>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {cloudStages.map((stage) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{stage.title}</h5>

              <p className="section-description ">{stage.description}</p>
            </div>
          ))}
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const cloudServices = [
  {
    title: "Cloud Strategy Services",
    description:
      "Anclatechs delivers a holistic approach to cloud transformation that accelerates a company’s move to digital business. From cloud advisory to cloud build, from cloud migration to next generation enterprise cloud governance, we can be your anchor partner.",
    learnMore: "/",
  },
  {
    title: "Cloud Migration Services",
    description:
      "We help determine the best cloud approach for organizations - public cloud, private cloud or hybrid cloud. With our migration factory model, we provide faster, cost-effective and risk-free transition with minimal business impact.",
    learnMore: "/",
  },
  {
    title: "Cloud Infrastructure and Implementation",
    description:
      "We ensure seamless cloud adoption with built-in security, resiliency, availability, and scalability. Our expertise spans across Salesforce Cloud, Oracle Cloud and AWS implementation & more",
    learnMore: "/",
  },
  {
    title: "Cloud Security and Governance Services",
    description:
      "We help establish standards for consistency with defined processes, metrics and reporting. We help integrate new processes with existing cloud governance policies, boards and tools.",
    learnMore: "/",
  },
  {
    title: "Cloud Management Services",
    description:
      "We enable high availability and continuous optimization of cloud ecosystem with mWatch, our proprietary platform. We optimize cloud operations to enable control of security and access to systems, and manage backup and disaster recovery processes.",
    learnMore: "/",
  },
  {
    title: "Cloud Modernization Services",
    description:
      "We help enterprises modernize their cloud using cloud native services with built in security, availability, and scalability. With our Minimum Viable Cloud platform based approach, we provide a scalable platform for cloud adoption in microservices architecture.",
    learnMore: "/",
  },
];

const cloudStages = [
  {
    title: "Strategy:",
    description:
      "We help identify and assess the key decisions to embark and manage deployment of cloud in new and disruptive business models and revolutionize the customer experience.",
  },
  {
    title: "Speed:",
    description:
      "Anclatechs enables companies to quickly migrate to the cloud and scale in a factory-based model, which ensures efficiency and the flexibility to align with business needs.",
  },
  {
    title: "Next-generation platforms::",
    description:
      "Leverage latest technologies; We develop tailored industry-specific/enterprise solutions aimed at helping businesses to foster productivity, inclusivity and innovation.",
  },
];
