import React from "react";
import ButtonBase from "../../components/button/ButtonBase";
import clsx from "clsx";
import NavLinkContent from "./NavLinkContent";
import { NavLink } from "react-router-dom";

function NavbarMobileLink(props) {
  const { label, submenus, path } = props.link;
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpanded = () => setIsExpanded((p) => !p);

  return (
    <div className="">
      {submenus.length ? (
        <ButtonBase className="justify-between w-full h-10" onClick={toggleExpanded}>
          {label}
          <span className="material-icons ml-2">{isExpanded ? "expand_less" : "expand_more"}</span>
        </ButtonBase>
      ) : (
        <div className="py-2">
          <NavLink to={`${path}`} className="h-10 py-2 ">
            {label}
          </NavLink>
        </div>
      )}
      <div className={clsx("overflow-hidden transition-all", !isExpanded ? "h-0" : "h-auto")}>
        <NavLinkContent submenus={submenus} />
      </div>
    </div>
  );
}

export default NavbarMobileLink;
