import clsx from "clsx";
import React from "react";

/**
 *
 * @param {React.ComponentPropsWithoutRef<'div'>} props
 */
function SectionInfo(props) {
  const { className, ...rest } = props;

  return (
    <div className={clsx("p-4 md:p-5 md:w-6/12 w-full", className)} {...rest} />
  );
}

export default SectionInfo;
