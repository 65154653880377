import SectionHeader from "../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../components/section/Section";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import SectionContactForm from "../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../components/sectionContact/SectionContactSupport";
import SectionServiceStickyLinks from "../../components/sectionServiceStickyLinks/SectionServiceStickyLinks";
import AnclaList from "../../components/sectionTitle/AnclaList";

export default function DigitalMarketing() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Digital Marketing",
    sectionHeaderDescription: "Drive customer growth with digital marketing services",
    sectionDescription: [
      "Winning customers in today’s marketplace requires more than a digital brand presence. You need personalized offerings, seamless navigation and real-time engagement across all channels. Winning companies prioritize and activate digital marketing solutions and extract maximum value from the data they collect every day.",
      "Our digital marketing services help you strategize, implement, run and maintain successful omni-channel marketing and multi-lingual digital marketing solutions.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      <SectionServiceStickyLinks />
      <Section>
        <SectionTitle>We offer a full range of services: </SectionTitle>

        <div className="mt-18">
          {Digitalservices.map((service, key) => (
            <div className="mt-10" key={key}>
              <h5 className="text-primary-400 text-h5 font-semibold">{service.title}</h5>

              <ol className="mt-5">
                {service.descriptionItems.map((value, key) => (
                  <AnclaList key={key}>{value}</AnclaList>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const Digitalservices = [
  {
    title: "Digital marketing strategy and user experience design services",
    descriptionItems: ["Customized digital strategy and maturity assessment for your digital marketing solutions", "Roadmap and architecture based on your business priorities"],
  },
  {
    title: "Implementation of digital marketing solutions",
    descriptionItems: [
      "Mobile and social media applications that entice consumers and enrich the user experience",
      "Content migration projects using proven digital marketing architecture methodologies and industry best practices",
    ],
  },
  {
    title: "Maintenance and operation of all digital marketing solutions",
    descriptionItems: [
      "Maintenance and operation of all digital marketing solutions",
      "Applications and systems maintained at high performance levels",
      "Partner and third-party vendor coordination",
    ],
  },
];
