import clsx from "clsx";
import React from "react";

/**
 *
 * @param {{header: boolean} & React.ComponentPropsWithoutRef<'h6'>} props
 */
function SectionTitle(props) {
  const { className, header, white, ...rest } = props;
  return (
    <p
      {...rest}
      className={clsx(
        `${white ? "text-white" : "text-primary"} mb-1`,
        header && ` text-h3  md:text-h2 lg:text-h1 font-medium`,
        !header && `text-primary text-h5 md:text-h3 font-medium`,
        className
      )}
    >
      {props.children}
    </p>
  );
}

export default SectionTitle;
