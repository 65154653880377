export const RouteEnum = {
    // services
    CLOUD: "/services/cloud",
    DIGITAL_ANALYTICS: "/services/digital-analytics",
    DIGITAL_MARKETING: "/services/digital-marketing",
    USER_EXPERIENCE_DESIGN: "/services/user-experience-design",
    AGILE: "/services/agile",
    AUTOMATION: "/services/automation",
    APPLICATION_MANAGED_SERVICES: "/services/application-managed-services",
    BUSINESS_PROCESS_MANAGEMENT: "/services/business-process-management",
    APPLICATION_DEVELOPMENT_AND_MANAGEMENT: "/services/application-development-and-management",
    DEVOPS: "/services/devops",
    INFORMATION_STRATEGY_AND_GOVERNANCE: "/services/information-strategy-and-governance",
    QUALITY_ASSURANCE_STRATEGY_AND_PROCESS: "/services/quality-assurance-strategy-and-process",
    APPLICATION_AND_INFRASTRUCTURE_OPTIMIZATION: "/services/application-and-infrastructure-optimization",
    EMPLOYEE_TRAINING_AND_MICROLEARNING: "/services/employee-training-and-microlearning",
    BIANNUAL_SECONDARY_SCHOOL_CODING_CHALLENGE: "/services/bi-annual-ss-coding-challenge",
    COMPUTER_TRAINING_SCHOLARSHIP_PROGRAMME: "/services/computer-training-scholarship-programme",
    ABOUT_US: "/about-us",
    CONTACT_US: "/contact-us",
    BUILD_PROJECT: "/build-project",
    BLOG: "/blog",
    BLOG_CATEGORY: "/blog-category/:category",
    BLOG_CATEGORY_ROUTE: "/blog-category",
    FAQ: "/faq",
    CAREERS: "/careers",
    TERMS_AND_CONDITION: "/terms-and-condition",
    PRIVACY: "/privacy",
    BLOG_DETAIL: "/blog/:slug",
    DATA_ANALYSIS: "/services/data-analysis",

    // INDUSTRIES
    HEALTH: "/industries/health",
    EDUCATION: "/industries/education",
    BANKING: "/industries/banking",
    ENTERPRISES_TECHNOLOGY: "/industries/enterprises-technology",
    CONSUMER_TECHNOLOGY: "/industries/consumer-technology",
};

export const BoxType = {
    DEFAULT: "default",
    BLUE: "blue",
    BORDER: "border",
};