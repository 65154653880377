import React, { useEffect } from "react";
// import { Route, Router } from "react-router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Cloud from "./pages/services/cloud/Cloud";
import { RouteEnum } from "./utils/constants";
import DataAnalysis from "./pages/services/dataAnalysis/DataAnalysis";
import DigitalMarketing from "./pages/digitalMarketing/DigitalMarketing";
import UserExperienceDesign from "./pages/services/userExperienceDesign/UserExperienceDesign";
import Agile from "./pages/services/agile/Agile";
import Devops from "./pages/services/devops/Devops";
import QualityAssuranceProcess from "./pages/services/qualityAssuranceProcess/QualityAssuranceProcess";
import ApplicationInfrastructureOptimisation from "./pages/services/applicationInfrastructureOptimisation/ApplicationInfrastructureOptimisation";
import Banking from "./pages/industries/banking/Banking";
import Health from "./pages/industries/health/Health";
import Education from "./pages/industries/eduction/Education";
import ConsumersTechnology from "./pages/industries/consumersTechnology/ConsumersTechnology";
import EnterpriseTechnology from "./pages/industries/enterpriseTechnology/EnterpriseTechnology";
import ContactUs from "./pages/contactUs/ContactUs";
import ScrollToTop from "./utils/useScrollToTop";
import Blog from "./pages/blog/Blog";
import BlogDetail from "./pages/blog/BlogDetail";
import InformationStrategyAndGovernance from "./pages/services/informationStrategyAndGovernance/InformationStrategyAndGovernance";
import AutomationSolutions from "./pages/services/automationSolutions/AutomationSolutions";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ApplicationManagedServices from "./pages/services/applicationManagedServices/ApplicationManagedServices";
import BusinessProcessManagement from "./pages/services/businessProcessManagement/BusinessProcessManagement";
import ApplicationDevelopmentAndManagement from "./pages/services/applicationDevelopmentAndManagement/ApplicationDevelopmentAndManagement";
import BiAnnualSecondarySchoolChallange from "./pages/services/biAnnualSecondarySchoolChallange/BiAnnualSecondarySchoolChallange";
import ComputerTrainingScholarshipProgramme from "./pages/services/computerTrainingScholarshipProgramme/ComputerTrainingScholarshipProgramme";
import About from "./pages/services/about/About";
import BuildProject from "./pages/buildProject/BuildProject";
import BlogCategory from "./pages/blog/blogCategory";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Router>
        <ScrollToTop>
          <Switch>
            {ROUTES.map((route, key) => (
              <Route key={key} {...route} />
            ))}
          </Switch>
        </ScrollToTop>
      </Router>
    </React.Suspense>
  );
}

export default App;

const ROUTES = [
  {
    path: RouteEnum.CLOUD,
    component: Cloud,
  },
  {
    path: RouteEnum.DIGITAL_ANALYTICS,
    component: DataAnalysis,
  },
  {
    path: RouteEnum.DIGITAL_MARKETING,
    component: DigitalMarketing,
  },
  {
    path: RouteEnum.USER_EXPERIENCE_DESIGN,
    component: UserExperienceDesign,
  },
  {
    path: RouteEnum.AGILE,
    component: Agile,
  },
  {
    path: RouteEnum.DEVOPS,
    component: Devops,
  },
  {
    path: RouteEnum.QUALITY_ASSURANCE_STRATEGY_AND_PROCESS,
    component: QualityAssuranceProcess,
  },
  {
    path: RouteEnum.APPLICATION_AND_INFRASTRUCTURE_OPTIMIZATION,
    component: ApplicationInfrastructureOptimisation,
  },
  {
    path: RouteEnum.BANKING,
    component: Banking,
  },
  {
    path: RouteEnum.HEALTH,
    component: Health,
  },
  {
    path: RouteEnum.BLOG_DETAIL,
    component: BlogDetail,
  },
  {
    path: RouteEnum.BLOG_CATEGORY,
    component: BlogCategory,
  },
  {
    path: RouteEnum.BLOG,
    component: Blog,
  },
  {
    path: RouteEnum.EDUCATION,
    component: Education,
  },
  {
    path: RouteEnum.CONSUMER_TECHNOLOGY,
    component: ConsumersTechnology,
  },
  {
    path: RouteEnum.BUILD_PROJECT,
    component: BuildProject,
  },
  {
    path: RouteEnum.INFORMATION_STRATEGY_AND_GOVERNANCE,
    component: InformationStrategyAndGovernance,
  },
  {
    path: RouteEnum.AUTOMATION,
    component: AutomationSolutions,
  },
  {
    path: RouteEnum.ENTERPRISES_TECHNOLOGY,
    component: EnterpriseTechnology,
  },
  {
    path: RouteEnum.CONTACT_US,
    component: ContactUs,
  },
  {
    path: RouteEnum.BUILD_PROJECT,
    component: ContactUs,
  },
  {
    path: RouteEnum.ABOUT_US,
    component: About,
  },
  {
    path: RouteEnum.APPLICATION_MANAGED_SERVICES,
    component: ApplicationManagedServices,
  },
  {
    path: RouteEnum.BUSINESS_PROCESS_MANAGEMENT,
    component: BusinessProcessManagement,
  },
  {
    path: RouteEnum.APPLICATION_DEVELOPMENT_AND_MANAGEMENT,
    component: ApplicationDevelopmentAndManagement,
  },
  {
    path: RouteEnum.BIANNUAL_SECONDARY_SCHOOL_CODING_CHALLENGE,
    component: BiAnnualSecondarySchoolChallange,
  },
  {
    path: RouteEnum.COMPUTER_TRAINING_SCHOLARSHIP_PROGRAMME,
    component: ComputerTrainingScholarshipProgramme,
  },
  {
    path: RouteEnum.EMPLOYEE_TRAINING_AND_MICROLEARNING,
    component: ComputerTrainingScholarshipProgramme,
  },
  { path: "/", component: Home },
];
