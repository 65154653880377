import React from "react";
import Section from "../../components/section/Section";
import SectionInfo from "../../components/sectionInfo/SectionInfo";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import Support from "../../pages/home/img/Support.svg";
import { NavLink } from "react-router-dom";
import { RouteEnum } from "../../utils/constants";

export default function SectionContactSupport() {
  return (
    <Section className="flex flex-wrap min-h-screen items-center">
      <SectionInfo>
        <img src={Support} alt="support" />
      </SectionInfo>

      <SectionInfo className="text-center md:text-left ">
        <SectionTitle className="text-center md:text-left  text-h6">Let’s talk about your needs </SectionTitle>
        <NavLink to={`${RouteEnum.CONTACT_US}`}>
          <button
            type="button"
            class="bg-blue-600 mt-10 text-gray-200 border-2 text-primary  rounded-t-full rounded-l-full rounded-r-full hover:bg-primary-100 px-14 py-3 focus:outline-none"
          >
            Contact
          </button>
        </NavLink>
      </SectionInfo>
    </Section>
  );
}
