import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";

export default function EnterpriseTechnology() {
  const header = {
    sectionHeader: "Industries",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Enterprise Technology",
    sectionHeaderDescription: "Enabling technology companies to outperform competitors",
    sectionDescriptionTitle: "Stay ahead in a connected future with devices that deliver exceptional customer experiences",
    sectionDescription: [
      "High Tech companies are transforming at a rapid pace. This change is enabled by embracing emerging technologies, fast-paced innovation, accelerated product and services development, differentiated business models and going digital till the last mile to become part of cloud ecosystem. ",
      "Software Defined Everything (SDx) and Data driven Everything are redefining high tech products & services. By making use of new technologies that bring about a vast degree of automation, they help create an integrated technology infrastructure that can be holistically managed. Consequently, there is adoption of approaches such as Software-as-a Service, Product-as-a-Service, Software Defined Data Centers, Software Defined Networking, Software Defined Chips, and Model based Product Development. ",
      "High Tech companies focusing on enterprise software or networking or hardware must be continuously alert and aware of the ecosystem changes. It warrants working with a relevant partner to navigate through the maze of new technologies and processes so that they can stay focused on their core purpose.",
      " Anclatechs delivers digital transformation and technology services from product ideation to execution to sustenance, ML and AI based product support, cloud operations and professional services, data and analytics driven customer success enabling Technology clients to outperform the competition. “Born digital,” Anclatechs takes an agile, collaborative approach to creating customized solutions across the digital value chain including the entire Cloud infrastructure economy.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}
