import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function Education() {
  const header = {
    sectionHeader: "Industries",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Education",
    sectionHeaderDescription: "Opening up a world of digital solutions for the education sector",
    sectionDescriptionTitle: "Transforming Education with a Data-first and Cloud-first Approach",
    sectionDescription: [
      "The landscape and channels through which students and educators interact have leap frogged in the decade. This has resulted in new opportunities for institutions to leverage data and a cloud first approach to improve student performance. Understand how to drive digital agility by leveraging the cloud for education.",
      "Helping education institutions engage constituents with richer experiences, empower educators and optimize operations",
      "In the last decade, there has been a paradigm shift in the goals of education institutions. Students are moving to institutions that create market-ready skills for successful careers, powered by experience-rich lifelong learning. As a result, educational institutional processes are becoming increasingly learner-centric. Functions are designed:",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <div className="mt-5">
          <ol className="mt-3">
            {listOne.map((value, key) => (
              <AnclaList key={key}>{value}</AnclaList>
            ))}
          </ol>
        </div>

        <h5 className="section-description mt-10 py-5  px-4 pb-5">
          Anclathech’s digital transformation strategies, roadmaps and implementations are designed to deliver reach, affordability and most of all, an adaptive environment to
          support student needs, empower educators and optimize operations . Our solutions touch every aspect of the new digital landscape that educational institutions are
          building by
        </h5>

        <div className="mt-5">
          <ol className="mt-3">
            {listTwo.map((value, key) => (
              <AnclaList key={key}>{value}</AnclaList>
            ))}
          </ol>
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const listOne = [
  "To manage student acquisition and retention through intimate, integrated and timely interactions",
  "Meet student need through personalization of a dynamic curriculum",
  "Improve learning outcomes by understanding learnability and interest of the student",
  "To provide convenient digital collaboration and flexible content access through technology on campus",
];

const listTwo = [
  "Delivering richer,broader and personalized experiences for constituents",
  "Reimagining, reconfiguring and digitalizing core administrative processes for speed and flexibility",
  "Sense and response” with deep insight across all constituents",
  "Enabling new delivery models to access new revenues",
];
