import React from "react";
import clsx from "clsx";
import { ReactComponent as Logo } from "./logo.svg";
import NavLink from "./NavLink";
import LINKS from "./routeLinks";
import { Link } from "react-router-dom";
import NavbarMobileLink from "./NavbarMobileLink";
import ScrollToTop from "./ScrollToTop";

function Header(props) {
  const [isMobileMenu, setIsMobileMenu] = React.useState(false);
  // eslint-disable-next-line
  const [isFixedHeader, setIsFixedHeader] = React.useState(false);

  function toggleMobileMenu() {
    setIsMobileMenu((p) => !p);
  }

  const desktopContent = () => (
    <div className="lg:container bg-white flex items-center justify-between h-20 m-auto px-4">
      <Link to="/">
        <Logo />
      </Link>
      <div className="hidden lg:flex items-center justify-between h-20 space-x-4">
        {LINKS.map((link, index) => (
          <NavLink key={index} link={link} />
        ))}
      </div>
      <div
        className="lg:hidden fixed right-4 text-primary"
        onClick={toggleMobileMenu}
      >
        <span className="material-icons text-primary pointer">menu</span>
      </div>
    </div>
  );

  React.useLayoutEffect(() => {
    function handleScroll(e) {
      setIsFixedHeader(window.scrollY >= 100);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={clsx(
          "absolute w-full z-10 text-primary-300 bg-white",
          isFixedHeader ? "bg-white shadow" : ""
        )}
      >
        {desktopContent()}
      </div>
      <div
        className={clsx(
          "text-primary-300  z-appBar w-full hidden bg-white lg:block absolute",
          isFixedHeader ? "top-0" : "-top-20"
        )}
      >
        {desktopContent()}
      </div>
      {isFixedHeader && <ScrollToTop />}
      <div
        style={{
          transform: isMobileMenu ? "translateX(0%)" : "translateX(100%)",
          color: "#243375",
          background: "#FCFDFF",
        }}
        className={clsx(
          "fixed top-0 right-0 bg-white flex lg:hidden flex-col w-full sm:max-w-xs h-full bg-white transition-transform z-50",
          {}
        )}
      >
        <div className="flex justify-between items-center h-20 px-4">
          <Logo />
          <div onClick={toggleMobileMenu}>
            <span className="material-icons">close</span>
          </div>
        </div>
        <div className="flex-1 p-4 overflow-auto divide-y-2">
          {LINKS.map((link, index) => (
            <NavbarMobileLink key={index} link={link} />
          ))}
        </div>
        <div className="flex justify-center space-x-4 p-4"></div>
      </div>
    </>
  );
}

export default Header;
