import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";

export default function ConsumersTechnology() {
  const header = {
    sectionHeader: "Industries",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Consumers Technology",
    sectionHeaderDescription: "Putting Intelligence into tomorrow’s products",
    sectionDescriptionTitle: "Stay ahead in a connected future with devices that deliver exceptional customer experiences",
    sectionDescription: [
      "The consumer technology industry is at the cusp of exciting transformation and expansion. The drivers are Industry 4.0, a prolific spread of network technologies & the internet, availability of diverse Internet of Things use cases, and dominance of cloud & analytics. Products are expected to be innovative connected, smart and intelligent, in addition to being leaner and quicker to market. ",
      "Consumer Technology companies have to contend with twin challenges - reaching out to new-age consumers with hyper-personalized services, across a multitude of devices and use cases as well as meeting competitive price points. ",
      "Anclatech’s service offerings help clients effectively tackle these challenges. In addition, it also enables them to attain a competitive edge by transforming products and services with emerging technologies to deliver exceptional customer experience, accelerate time-to-market and ensure business success. ",
      "To support clients in delivering the intelligent products and services of tomorrow today, AnclaTechs offers Product Development & Maintenance, Product Innovation & Transformation, Product & IT Operations, “Automation First” led Quality Engineering, product and services analytics.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}
