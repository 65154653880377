import React from "react";
import { NavLink } from "react-router-dom";

function NavLinkContent(props) {
  const { submenus, noLabel } = props;

  return (
    <div>
      <div
        className={`${
          noLabel ? "" : "grid grid-cols-1 md:grid-cols-3 sm:grid-cols-1 gap-3"
        } p-5 shadow-lg rounded-2xl`}
      >
        {submenus.map((menu) => (
          <div className="p-6 space-y-2">
            {menu.label && (
              <h6 className="text-h5 text-primary font-medium">{menu.label}</h6>
            )}
            {menu.submenus.map((link) => (
              <NavLink to={`${link.path}`} className="block text-h6 font-light">
                {link.label}
              </NavLink>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavLinkContent;
