import SectionHeader from "../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../components/Layout";
import HomeMascot from "../../pages/services/agile/img/HomeMascot.svg";
import Section from "../../components/section/Section";
import SectionContactForm from "../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../components/sectionContact/SectionContactSupport";
import SectionServiceStickyLinks from "../../components/sectionServiceStickyLinks/SectionServiceStickyLinks";
import { NavLink } from "react-router-dom";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import { RouteEnum } from "../../utils/constants";
import { useGetBlogByCategoryListQuery } from "./BlogSliceApi";
import ContentLoader from "react-content-loader";

export default function BlogCategory(props) {
  //   const [page, setPage] = React.useState(1);
  const header = {
    sectionHeader: "Welcome to",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Blog Category",
    sectionHeaderDescription:
      "Where we tell our stories, and teach 'how to manage and grow your business', as well as share news updates on latest technologies and how it applies to business today. - Anclatechs 😎",
    sectionDescription: [],
  };

  const { match } = props;

  const { data, error, isLoading } = useGetBlogByCategoryListQuery(
    React.useMemo(
      () => match.params.category,
      [
        match.params.category,
        //   page,
      ]
    )
  );
  console.log("data", data, error, isLoading);
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      <SectionServiceStickyLinks />
      <Section>
        <div className="mt-5">
          {error && (
            <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
              <p class="font-bold">Something went wrong</p>
              <p>Oh no, there was an error, please reload</p>
            </div>
          )}
          {isLoading && <ContentLoader />}

          {data &&
            (data?.results.length >= 1 ? (
              <>
                {data?.results?.map((service, key) => (
                  <div className="mt-10 flex flex-col md:flex-row mb-18" key={key}>
                    <div className="w-full p-2 md:w-4/6">
                      <NavLink
                        to={`${RouteEnum.BLOG}/${service.slug}`}
                        style={{
                          backgroundImage: `url(${service.image})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          height: "300px",
                          maxHeight: "300px",
                        }}
                        className="w-full h-96 block"
                      ></NavLink>
                    </div>

                    <div className="w-full p-2 md:w-5/12">
                      <h5 className="text-primary-200 mb-3">{service?.category?.name || "Our Story"}</h5>
                      <SectionTitle>
                        <NavLink to={`${RouteEnum.BLOG}/${service.slug}`} className="capitalize">
                          {service.title}
                        </NavLink>
                      </SectionTitle>
                      <div className="flex mt-5">
                        <p>{service.publish}</p> <span className="mx-2">|</span> <p>{service.readtime}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <SectionTitle className="text-center">No Content for {match.params.category} Category</SectionTitle>
            ))}
        </div>

        {/* <div className="flex justify-between mt-10">
          <div>
            {data?.previous !== null && (
              <button
                onClick={() => setPage(page - 1)}
                class="bg-transparent flex align-center hover:bg-primary-400 text-primary font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              >
                <span class="material-icons-outlined">keyboard_arrow_left</span>
                Previous
              </button>
            )}
          </div>

          <div>
            {data?.next !== null && (
              <button
                onClick={() => setPage(page + 1)}
                class="bg-transparent flex align-center hover:bg-primary-400 text-primary font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              >
                Next
                <span class="material-icons-outlined">
                  keyboard_arrow_right
                </span>
              </button>
            )}
          </div>
        </div> */}
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}
