import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";

export default function ApplicationDevelopmentAndManagement() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Application Development and Support",
    sectionHeaderDescription: "Maximize the benefits of your critical applications",
    sectionDescription: [
      "In today’s everyday business environment, application failure or unexpected downtime can not only frustrate end users, it can devastate your bottom line. As budgets shrink and application environments grow more complex, you need an experienced strategic IT partner to bolster your application development and support.",
      "At Anclatechs, we work with you to understand your business and develop customized solutions to maximize application availability, agility and predictability. Our goal is to help your organization lower costs, reduce risk, improve ROI and speed your response to market opportunities so you can achieve your long-term objectives.",
      "We manage mission-critical applications for some of the world’s most successful companies, and we’re here to help you truly make digital real.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      {/* 
      <Section>
        <SectionTitle>
          <h3 className="text-primary text-h5">What can DevOps do for you? </h3>{" "}
        </SectionTitle>
        <div className="mt-5">
          {expertise.map((value) => (
            <h5 className="section-description  mt-3 pb-5">{value}</h5>
          ))}
        </div>
      </Section> */}

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}
