import React from "react";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import Helmet from "react-helmet";

export default function Layout(props) {
  return (
    <>
      <Helmet>
        <title>{`Ancla Technologies | ${props?.pageMeta?.title}`}</title>

        {/* The charset, viewport and author meta tags will always have the same value, so we hard code them! */}
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content={`Ancla Technologies | ${props?.pageMeta?.author}`} />
        <meta name="theme-color" content="#ffa40c" />
        <meta
          name="keywords"
          content="Ancla, Anclatech, Anclatechs, Ancla Technologies, enterprise solutions, web development company, enterprise solutions in Africa, IT consulting, mobile application development company,  IT consulting in Lagos, IT consulting in Nigeria, Data science, Outsourcing agency in Lagos, Outsourcing agency in Abuja, Outsourcing agency in Nigeria, software design, software engineering, software project management, software company, how to integrate paystack, web developer in lagos, software companies in lagos, software companies in lagos nigeria, software developers in lagos, it consulting, it consulting companies in lagos
	  "
        />
        {/* The rest we set dynamically with props */}
        <meta name="description" content={props?.pageMeta?.description} />
        <meta property="og:image" content="https://res.cloudinary.com/ancla8techs4/image/upload/v1592414974/Asset_51_v7jl9a.png" />

        {/* We pass an array of keywords, and then we use the Array.join method to convert them to a string where each keyword is separated by a comma */}
        {/* <meta name="keywords" content={props.pageMeta.keywords.join(",")} /> */}
      </Helmet>
      <Navbar />
      {props.children}
      <Footer />
    </>
  );
}
