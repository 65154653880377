import { RouteEnum } from "../../utils/constants";

const LINKS = [
  {
    label: "Home",
    path: "/",
    submenus: [],
  },
  {
    label: "Services",
    path: "",
    submenus: [
      {
        label: "Digital",
        largeMenu: true,
        submenus: [
          {
            label: "Cloud",
            path: RouteEnum.CLOUD,
          },
          {
            label: "Digital Analytics",
            path: RouteEnum.DIGITAL_ANALYTICS,
          },
          {
            label: "Digital Marketing",
            path: RouteEnum.DIGITAL_MARKETING,
          },
          {
            label: "User Experience Design",
            path: RouteEnum.USER_EXPERIENCE_DESIGN,
          },
        ],
      },
      {
        label: "Operations",
        submenus: [
          {
            label: "Agile",
            path: RouteEnum.AGILE,
          },
          {
            label: "Automation",
            path: RouteEnum.AUTOMATION,
          },
          {
            label: "Application Managed Services",
            path: RouteEnum.APPLICATION_MANAGED_SERVICES,
          },
          {
            label: "Business Process Management",
            path: RouteEnum.BUSINESS_PROCESS_MANAGEMENT,
          },
          {
            label: "Application development and management",
            path: RouteEnum.APPLICATION_DEVELOPMENT_AND_MANAGEMENT,
          },
        ],
      },
      {
        label: "IT Consulting",
        submenus: [
          {
            label: "Agile",
            path: RouteEnum.AGILE,
          },
          {
            label: "DevOPS",
            path: RouteEnum.DEVOPS,
          },
          {
            label: "Information Strategy and Governance",
            path: RouteEnum.INFORMATION_STRATEGY_AND_GOVERNANCE,
          },
          {
            label: "Quality Assurance Strategy and Process",
            path: RouteEnum.QUALITY_ASSURANCE_STRATEGY_AND_PROCESS,
          },
          {
            label: "Application and Infrastructure Optimization",
            path: RouteEnum.APPLICATION_AND_INFRASTRUCTURE_OPTIMIZATION,
          },
        ],
      },
      {
        label: "Featured Packages",
        submenus: [
          {
            label: "Employee Training and Microlearning",
            path: RouteEnum.EMPLOYEE_TRAINING_AND_MICROLEARNING,
          },
          {
            label: "DevOPS",
            path: RouteEnum.DEVOPS,
          },
          {
            label: "Information Strategy and Governance",
            path: RouteEnum.INFORMATION_STRATEGY_AND_GOVERNANCE,
          },
          {
            label: "Bi-annual Secondary School Coding Challenge",
            path: RouteEnum.BIANNUAL_SECONDARY_SCHOOL_CODING_CHALLENGE,
          },
          {
            label: "Computer Training Scholarship Programme",
            path: RouteEnum.COMPUTER_TRAINING_SCHOLARSHIP_PROGRAMME,
          },
        ],
      },
      {
        label: "Methodology",
        submenus: [
          {
            label: "Agile (Scrum)",
            path: RouteEnum.AGILE,
          },
        ],
      },
    ],
  },

  {
    label: "Industries",
    path: "",
    submenus: [
      {
        label: "",
        largeMenu: false,
        submenus: [
          {
            label: "Health",
            path: RouteEnum.HEALTH,
          },
          {
            label: "Education",
            path: RouteEnum.EDUCATION,
          },
          {
            label: "Banking",
            path: RouteEnum.BANKING,
          },
          {
            label: "Enterprises Technology",
            path: RouteEnum.ENTERPRISES_TECHNOLOGY,
          },
          {
            label: "Consumer Technology",
            path: RouteEnum.CONSUMER_TECHNOLOGY,
          },
        ],
      },
    ],
  },
  {
    label: "About",
    path: RouteEnum.ABOUT_US,
    submenus: [],
  },
  {
    label: "Blog",
    path: RouteEnum.BLOG,
    submenus: [],
  },
  {
    label: "Contact Us",
    path: RouteEnum.CONTACT_US,
    submenus: [],
  },
];

export default LINKS;
