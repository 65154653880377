import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function InformationStrategyAndGovernance() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Information Strategy and Governance",
    sectionHeaderDescription: "Build an IT strategy and governance framework that works for your organisation",
    sectionDescription: [
      "The right people having access to the right information at the right time can be the difference between an organization’s success or failure.",
      "Data and insight have become key to companies growing or optimizing operations. But while technology innovations have generated huge amounts of data, most enterprises struggle to make sense of it and leverage it to achieve real business outcomes.",
      "AnclaTechs consultants can help you assess your abundance information and identify data essential to running and growing your organization. To address your unique needs, We work with you to build an information strategy and governance framework customized to your business.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <h5 className="text-primary text-h4">Our sevices include:</h5>

        <div className="mt-18">
          <ol className="mt-5">
            {services.map((value, key) => (
              <AnclaList key={key}>{value}</AnclaList>
            ))}
          </ol>
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const services = [
  "Strategic business assessment to capture your organization’s goals and outcomes",
  "Information architecture strategy to optimize data storage and retrieval, including ensuring future scalability without additional capital investment",
  "Information strategy road map to ensure alignment of people, processes, tools and technology—both within the organization and across the value chain",
  "Project management during implementation of information architecture so you can effectively and efficiently use data and insights to achieve organizational goals",
  "Information governance to ensure appropriate valuation, creation, storage, use, archiving, retrieval and deletion of information",
];
