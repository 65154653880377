import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";

export default function About() {
  const header = {
    sectionHeader: "About Us",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Company Overview",
    sectionHeaderDescription: "Helping businesses leverage technology for growth",
    sectionDescription: [
      "Ancla Technologies; is an Information Technology and Outsourcing company with the mission to transform the way businesses functions.",
      "We help with development, maintenance and provision of IT support for application softwares; creating an atmosphere where businesses can leverage technology more without having to worry about the hiring process, maintenance of software and cost of engineers; allowing for growth and focus on core business functions.",
      "Our Vision is to help businesses leverage IT for scale, improved consumer utility, better customer service and improved revenue.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <SectionTitle>Anclatechs Values</SectionTitle>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-20">
          {services.map((service) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{service.title}</h5>

              <p className="section-description ">{service.description}</p>
            </div>
          ))}
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const services = [
  {
    title: "Expert Thinking",
    description:
      "Anclatechs brings robust skills and forward looking perspectives to solve customer challenges. We use proven knowledge to make recommendations and provide expert guidance to our customers.",
  },
  {
    title: "Unrelenting Dedication",
    description: "Anclatechs is driven to meet client needs with determination and grit. We embrace tough challenges and do not rest until the problem is solved, the right way.",
  },
  {
    title: "Collaborative Spirit",
    description:
      "Anclatechs believes in developing true partnerships. We foster a collegial environment where individual perspectives are respected and honest dialogue is expected.",
  },
  {
    title: "Fostering Curiosity",
    description: "We set our goals high and aim for the giant strides. At Anclatechs we fully embrace research & develoment.",
  },
  {
    title: "Partnering with our clients",
    description: "Every step of the way",
  },
  {
    title: "Love",
    description: "We are a team of talented industry experts, bonded by love, inspired and eager to work. We love. We work.. We create...",
  },
];
