import React from "react";
import Section from "../../components/section/Section";
import SectionDescription from "../../components/sectionDescription/SectionDescription";
import IconSquare from "./img/IconSquare.svg";
import Carousel from "react-elastic-carousel";
import "./Home.css";

const container = {
  // margin: "0 auto",
  marginTop: "3rem",
  width: "100%",
  //   color: "#333",
  //   background: "#419be0",
};

export default function HomePartnersSliders() {
  const carouselRef = React.useRef(null);
  const itemsPerPage = 1;
  const totalPages = Math.ceil(partners.length / itemsPerPage);
  let resetTimeout;

  const { innerWidth: width } = window;
  console.log("width", width);
  return (
    <Section>
      <div>
        <div className="flex relative justify-center w-full">
          <img src={IconSquare} alt="" style={{ zIndex: "-1" }} className="absolute top-0 right-0" />

          <div>
            {" "}
            <SectionDescription className="mt-8 text-center text-secondary-200 text-h5 max-w-3xl">Our clients</SectionDescription>
            <h6 className="text-center text-primary text-h4 font-medium">Trusted Tech Companies</h6>
          </div>
        </div>

        <div style={container}>
          <Carousel
            ref={carouselRef}
            enableAutoPlay
            outerSpacing={10}
            itemPadding={[0, 10]}
            onNextEnd={({ index }) => {
              console.log("index ne", index, totalPages);
              clearTimeout(resetTimeout);
              if (index + 1 === parseInt(`${width >= 850 ? 7 : 10}`)) {
                resetTimeout = setTimeout(() => {
                  carouselRef.current.goTo(0);
                }, 1500); // same time
              }
            }}
            itemsToShow={itemsPerPage}
            breakPoints={breakPoints}
          >
            {partners.map((item, key) => (
              <div key={key} style={{ width: "250px" }} className="flex items-center justify-center">
                <img className="carousel-image" src={item} alt={key} style={{ maxWidth: "100px" }} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </Section>
  );
}
const partners = [
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313366/fmit1_rfv4zt.jpg",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313365/aluuka_logo_dzvpt0.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313366/prelate_travel_rb9z7q.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313366/univelcity_1_jsouy7.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313366/fmit2_oj3rcx.jpg",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313365/acronis_cloud_backup_cndm1n.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313364/zoconut_dtvgnk.svg",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313365/charisol_eu5512.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313365/bitcards_c0xmyk.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313364/paymyrent_1_zrssfi.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313364/martify_logo_wdlsoc.png",
  "https://res.cloudinary.com/ancla8techs4/image/upload/v1628313364/pettycash_pxvyvm.png",
];

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 550, itemsToShow: 2 },
  { width: 850, itemsToShow: 5 },
  { width: 1150, itemsToShow: 5 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
];
