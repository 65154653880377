import clsx from "clsx";
import React from "react";

/**
 *
 * @param {React.ComponentPropsWithoutRef<'p'>} props
 */
function SectionDescription(props) {
  const { className, white, ...rest } = props;
  return (
    <p
      {...rest}
      className={clsx(
        `${white ? "text-white" : "text-primary-300"}`,
        "mb-4 text-h6 md:text-h5 font-extralight",
        className
      )}
    >
      {props.children}
    </p>
  );
}

export default SectionDescription;
