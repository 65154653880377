import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";

export default function BiAnnualSecondarySchoolChallange() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Bi-annual Secondary School Coding Challenge ",
    sectionHeaderDescription: "Monitoring and local level planning, comprehensive school and learner information.",
    sectionDescription: [
      "We believe in leveraging Technology for automation and efficiency. To educate and encourage more young pupils on Importance of STEM in our society we are preparing to kickstart an annual inter-school coding competition for Senior Secondary School students across Lagos State, with interesting awards and scholarship opportunities in view.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <SectionTitle>
          <h3 className="text-primary text-h5">Reimagine enterprise learning and development with Anclatech's microlearning platform </h3>{" "}
        </SectionTitle>
        <div className="mt-5">
          <h5 className="section-description  mt-3 pb-5">Our MicroLearning Plaform powered by GoLab Institute of Technology.</h5>
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}
