import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionServiceStickyLinks from "../../../components/sectionServiceStickyLinks/SectionServiceStickyLinks";

export default function BusinessProcessManagement() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Business Process Management",
    sectionHeaderDescription: "High quality processes require a penetrating view of business operations to drive continuous improvement.",
    sectionDescription: [
      "AnclaTechs works with you to optimize productivity, improve performance and reduce time-to-market. Our end-to-end business process management services span across process discovery, documentation, integration and automation to accelerate product development and encourage innovation.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      <SectionServiceStickyLinks />
      <Section>
        <SectionTitle>Anclatecs Services::</SectionTitle>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-20">
          {businessServices.map((service) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{service.title}</h5>

              <p className="section-description ">{service.description}</p>

              <p className="mt-8">
                <Link to={service.learnMore} className="section-description relative  learnMore">
                  Learn More
                </Link>
              </p>
            </div>
          ))}
        </div>

        <SectionTitle className="mt-32">Solutions and Frameworks:</SectionTitle>
        <h4 className="section-description max-w-3xl">Anclatechs has developed solutions and frameworks to meet specific industry needs:</h4>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {cloudStages.map((stage) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{stage.title}</h5>

              <p className="section-description ">{stage.description}</p>
            </div>
          ))}
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const businessServices = [
  {
    title: "Cloud Strategy Services",
    description:
      "Anclatechs delivers a holistic approach to cloud transformation that accelerates a company’s move to digital business. From cloud advisory to cloud build, from cloud migration to next generation enterprise cloud governance, we can be your anchor partner.",
    learnMore: "/",
  },
  {
    title: "Service Oriented Architecture and Integration Services:",
    description: "We help our clients define and deploy Service Oriented Architecture strategy, governance and operating model",
    learnMore: "/",
  },
  {
    title: "BPM services (consulting and enablement):",
    description: "We help migrate legacy applications and platforms to BPM-based smart applications and Service Oriented Architecture based integration platforms",
    learnMore: "/",
  },
  {
    title: "Business Rules Management System services (consulting and implementation):",
    description: "We implement policy-based controls and processes to reduce risks and meet compliance demands",
    learnMore: "/",
  },
  {
    title: "Process analytics and Complex Event Processing:",
    description: "We build real-time visibility capabilities into management systems to predict better and take pre-emptive action",
    learnMore: "/",
  },
  {
    title: "Collaborative Process Platform for Retail enterprises:",
    description: "This is a transformational solution platform developed leveraging Pega (a pure play BPM platform) to automate business processes across the supply chain.",
    learnMore: "/",
  },
];

const cloudStages = [
  {
    title: "Credit processing and exposure management systems: ",
    description:
      "These systems address corporate lending, risk exposure monitoring and management, loan processing and credit and loan portfolio management. Our partnership with IBM enables us to leverage the underlying agility and flexibility of IBM’s BPM platform to provide enhanced value to customers.",
  },
  {
    title: "Media planning and collaboration platform:",
    description:
      "The platform automates all processes involved in media planning, from execution to development. It also enables performance evaluation, monitoring and facilitates reliable end-to-end management of media campaigns.",
  },
  {
    title: "Collaborative Process Platform for Retail enterprises: ",
    description: "This is a transformational solution platform developed leveraging Pega (a pure play BPM platform) to automate business processes across the supply chain.",
  },
];
