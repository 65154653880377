import React from "react";
import NavLinkContent from "./NavLinkContent";
import { NavLink as ReachNavLink } from "react-router-dom";

function NavLink(props) {
  const { label, path, submenus } = props.link;

  return (
    <>
      {submenus.length ? (
        submenus.length === 1 ? (
          <div className="group relative">
            <ReachNavLink
              to=""
              className="text-button relative navlink text-h6 font-light hover:translate-x-1 cursor-pointer mx-2 py-1"
            >
              {label}
              {/* <span class="material-icons-outlined">expand_more</span> */}
            </ReachNavLink>
            <div className="absolute -bottom-2 w-full h-8" />
            <div
              style={{
                top: "calc(100% + 8px)",
                margin: "0 auto",
                background: "#FCFDFF",
              }}
              className="absolute w-max right-0  hidden bg-white group-hover:block bg-white"
            >
              <NavLinkContent submenus={submenus} noLabel />
            </div>
          </div>
        ) : (
          <div className="group">
            <ReachNavLink
              to=""
              className="text-button relative navlink text-h6 font-light hover:translate-x-1 cursor-pointer mx-2 py-1"
            >
              {label}
              {/* <span class="material-icons-outlined">expand_more</span> */}
            </ReachNavLink>
            <div className="absolute -bottom-2 w-full h-2" />
            <div
              style={{
                top: "calc(100% - 20px)",
                margin: "0 auto",
                background: "#FCFDFF",
              }}
              className="absolute w-max right-0 left-0 hidden bg-white group-hover:block bg-white"
            >
              <NavLinkContent submenus={submenus} />
            </div>
          </div>
        )
      ) : (
        <div className="group ">
          <ReachNavLink
            to={path}
            exact
            activeClassName="navlink navlink-active"
            className="text-button  relative text-h6 mx-2 font-light hover:translate-x-1 cursor-pointer py-1 "
          >
            {label}
          </ReachNavLink>
        </div>
      )}
    </>
  );
}

export default NavLink;
