import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionServiceStickyLinks from "../../../components/sectionServiceStickyLinks/SectionServiceStickyLinks";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function ApplicationManagedServices() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Application Managed Services",
    sectionHeaderDescription: "Aligning applications with strategic goals",
    sectionDescription: [],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      <SectionServiceStickyLinks />
      <Section>
        <SectionTitle>Turn IT into a strategic partner for business growth</SectionTitle>
        <div className="mt-5">
          <h5 className="section-description  mt-3 pb-5">
            To compete in today’s dynamic world, IT leaders must deploy new technologies rapidly to help grow their businesses while controlling IT costs. At the same time, they
            have to manage their current application portfolios and maintain essential run-the-business operations while eliminating redundancies and inefficiencies across the
            enterprise. Enterprises need an efficient Application Managed Services (AMS) partner to help:
          </h5>
        </div>

        <div className="mt-8">
          <ul>
            {marketingSolutions.map((solution) => (
              <AnclaList>{solution}</AnclaList>
            ))}
          </ul>
        </div>
      </Section>
      <Section>
        <SectionTitle>Overview of Anclatech's application managed service practice</SectionTitle>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-20">
          {managedServices.map((service) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{service.title}</h5>

              <p className="section-description ">{service.description}</p>

              <p className="mt-8">
                <Link
                  // to={service.learnMore}
                  className="section-description relative  learnMore"
                >
                  Learn More
                </Link>
              </p>
            </div>
          ))}
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const managedServices = [
  {
    title: "Application modernization",
    description: "Identify opportunities to enhance customer experience, reduce costs and speed processes through automation, new features or add-ons.",
    learnMore: "/",
  },
  {
    title: "DevOps",
    description:
      "Deliver services across the full life cycle, from transition planning and management, engineering support and testing automation to rightsourcing, Agile and Lean IT.",
    learnMore: "/",
  },
  {
    title: "Agile centre of Excellence",
    description:
      "Support clients worldwide and promote collaboration between onsite and remote teams by hosting Agile experts, automated test engineers, dynamic architecture engineers, CD engineers, Agile analysts and UX designers.",
    learnMore: "/",
  },
  {
    title: "Learn IT capabilities",
    description:
      "Focus on identifying and eliminating unproductive activities that detract value from the business, resulting in lost revenue, reduced productivity or poor customer service.",
    learnMore: "/",
  },
  {
    title: "Business aligned IT services",
    description:
      "Our customer-centric model incorporates application outsourcing, rightsizing and rebadging. Services include process optimization, portfolio rationalization and partnership governance.",
    learnMore: "/",
  },
];

const marketingSolutions = [
  "Meet run-the-business demands without interrupting services",
  "Lower the total cost of ownership to free up funds for innovation",
  "Focus resources on growing the business while supporting the enterprise",
  "Maintain visibility and control over applications while lowering costs",
];
