import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionTitle from "../../../components/sectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import SectionServiceStickyLinks from "../../../components/sectionServiceStickyLinks/SectionServiceStickyLinks";

export default function AutomationSolutions() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Automation Solutions",
    sectionHeaderDescription: "Intelligence automation for radical business transformation",
    sectionDescription: [
      "Intelligent automation has become a game-changer. It has already established itself firmly as the most effective paths to organizational agility and efficiency. Smart organizations are now conscious of the urgency in deploying intelligent automation. They know it can improve the use of their IT assets, help meet organizational strategies, deliver cost reduction goals, enable the adoption of new technologies and transform customer experience.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />
      <SectionServiceStickyLinks />
      <Section>
        <SectionTitle>Automation Services</SectionTitle>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-20">
          {automationServices.map((service) => (
            <div className="mt-10">
              <h5 className="text-primary text-h5 font-semibold mb-5">{service.title}</h5>

              <p className="section-description ">{service.description}</p>

              <p className="mt-8">
                <Link
                  // to={service.learnMore}
                  className="section-description relative  learnMore"
                >
                  Learn More
                </Link>
              </p>
            </div>
          ))}
        </div>
      </Section>

      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const automationServices = [
  {
    title: "Business Process Automation",
    description:
      "We help businesses automate processes involving repetitive activities. We have deep expertise in automating processes in IT operations (new use onboarding), financial services (account payable provisioning, payment processing, reconciliation and claims processing), manufacturing processes (price comparison, reporting, and data monitoring), and government processes (verification, integrating legacy systems and daily reports).",
    learnMore: "/",
  },
  {
    title: "Application Maintenance and Support",
    description:
      "Our automation-first approach, backed by proprietary end-to-end workflow-driven platforms, help analyze complex application portfolios, and design automation strategies right from the transition phase. As part of AMS engagement, we support automations majorly for routine SOP-based and Runbook-based activities. Our automation landscape also cover vital activities in AMS such as monitoring (health check monitoring, security monitoring), reporting and areas within L2 support.",
    learnMore: "/",
  },
  {
    title: "Application Development",
    description:
      "Our automation technology landscape along with proprietary Intellectual Properties (IPs) covers a range of home-grown platforms to accelerate end-to-end IT lifecycle automation. Auto code generation through CodeMill helps develop quality code which is tailor-made to meet client’s specific standards faster than ever before. Our enterprise DevOps platform CAPE, enables pre-built integrated DevOps tools connectors, enterprise-wide metrics, aggregated reporting, analytics and visualization across multiple tools and helps businesses take real time decisions.",
    learnMore: "/",
  },
];
