import React from "react";
import Section from "../../components/section/Section";
import SectionDescription from "../../components/sectionDescription/SectionDescription";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import SectionInfo from "../../components/sectionInfo/SectionInfo";
import PropTypes from "prop-types";

/**
 *
 * @param {React.ComponentPropsWithoutRef<'p'>} props
 */
function SectionHeader(props) {
  const { sectionHeader, sectionHeaderImage, sectionTitle, sectionHeaderDescription, sectionDescriptionTitle, sectionDescription } = props;
  return (
    <>
      <Section className="flex flex-wrap min-h-screen items-center">
        <SectionInfo>
          <div>
            <h5 className="text-primary-300 text-h5 font-light capitalize">{sectionHeader}</h5>
            <SectionTitle className="mt-14 font-semibold capitalize">{sectionTitle}</SectionTitle>
            <SectionDescription className="mt-10 max-w-md">{sectionHeaderDescription}</SectionDescription>
          </div>
        </SectionInfo>
        <SectionInfo className="flex justify-center">
          <img src={sectionHeaderImage} alt="sectionTitle" className="w-full" style={{ maxWidth: "450px" }} />
        </SectionInfo>
      </Section>
      <div className="container max-w-screen-ac mx-auto py-5">
        <h5 className="text-primary text-h4 px-4 mb-5">{sectionDescriptionTitle}</h5>

        {sectionDescription.map((value) => (
          <h5 className="section-description  px-4 pb-5">{value}</h5>
        ))}
      </div>
    </>
  );
}

SectionHeader.propTypes = {
  sectionHeader: PropTypes.string,
  sectionHeaderImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  sectionHeaderDescription: PropTypes.string,
};

export default SectionHeader;
