import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function Banking() {
  const header = {
    sectionHeader: "Industries",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Banking",
    sectionHeaderDescription: "Transform your financial Institution into a next-genation business",
    sectionDescriptionTitle: "Embrace the future of banking",
    sectionDescription: [
      "Anclatechs focuses on helping banks and financial institutions better engage with customers and users. We deliver mission-critical infrastructure and software solutions so you can drive your business forward globally. ",
      "To support the future of banking, we work with you to provide scalable services, manage and execute strategic initiatives and create innovative delivery models—like omnidirectional banking. ",
      "By leveraging our technical expertise, track record of global clients and our global delivery model, you can evolve into a next-generation financial institution.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <h5 className="text-primary text-h5">Primary areas of focus</h5>
        <div className="mt-5 grid gap-3 grid-cols-1 md:grid-cols-2">
          {focusArea.map((focus) => (
            <div className="mt-18">
              <h5 className="text-primary text-h6">{focus.title}</h5>
              <ol className="mt-3">
                {focus.description.map((value, key) => (
                  <AnclaList key={key}>{value}</AnclaList>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const focusArea = [
  {
    title: "Cooperate Banking",
    description: ["Online Banking", "Mobile Banking"],
  },
  {
    title: "Cards and Payments",
    description: ["Rewards Management", "Merchant Interfaces", "Customer Interfaces"],
  },
];
