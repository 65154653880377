import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function ApplicationInfrastructureProcess() {
  const header = {
    sectionHeader: "Services",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Application & Infrastructure Optimization",
    sectionHeaderDescription: "Get a single point of ownership for unified application and infrastructure support",
    sectionDescription: [
      "The key to effectively managing your IT environment is having a single point of ownership and appropriately mapping both applications and infrastructure. ",
      "At Anclatechs, we bring together infrastructure management and technical support to give you unified support and quick resolution. Our approach combines data center management, infrastructure management, end-user computing services and IT application management, resulting in proactive and all-up managed application and infrastructure support.",
      " To deliver optimal performance of your IT environment, we’ve created an infrastructure management solution that enables a holistic view of infrastructure and applications as a single, seamless entity. We can assume ownership of your IT infrastructure, applications and operations, freeing you to focus on:",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <h5 className="text-primary text-h4">Our quality assurance sevices include:</h5>

        <div className="mt-18">
          <ol className="mt-5">
            {services.map((value, key) => (
              <AnclaList key={key}>{value}</AnclaList>
            ))}
          </ol>
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const services = [
  "Increasing predictability in cost of operations and defined controls",
  "Boosting end-customer satisfaction and IT staff satisfaction",
  "Optimizing infrastructure and application availability and performance",
  "Improving capacity planning to meet the growing demands of business",
  "Making IT operations more efficient and demonstrating continuous improvement",
  "Transforming your business through technological advancement",
];
