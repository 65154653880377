import SectionHeader from "../../../components/sectionHeader/SectionHeader";
import React from "react";
import Layout from "../../../components/Layout";
import HomeMascot from "./img/HomeMascot.svg";
import Section from "../../../components/section/Section";
import SectionContactForm from "../../../components/sectionContact/SectionContactForm";
import SectionContactSupport from "../../../components/sectionContact/SectionContactSupport";
import AnclaList from "../../../components/sectionTitle/AnclaList";

export default function Health() {
  const header = {
    sectionHeader: "Industries",
    sectionHeaderImage: HomeMascot,
    sectionTitle: "Health",
    sectionHeaderDescription: "Transform your health Institution into a next-generation facility",
    sectionDescriptionTitle: "Embrace the future in Health",
    sectionDescription: [
      `Healthcare management systems, also known as healthcare information management systems, are designed to help healthcare providers collect, store, retrieve and exchange patient healthcare information more efficiently and enable better patient care.
      These technology projects — which are often compliance-driven — can require significant investments in time and resources.
      `,
      `A healthcare management system is much more than a schematic IT project. Digitizing clinical and patient information are only parts of the process of moving to a healthcare management system. There is also the Medical team & professionals who will be using the system every day — doctors, nurses, technicians, medical receptionists and many others.
      input from them are required and needed to be engaged early in the process so they have a say in how the system is designed. Appropriate training, critical User Experience & Intutive interface is needed for smooth transitioning.
      `,
      "By leveraging our technical expertise, track record of global clients and our global delivery model, you can evolve into a next-generation health facility.",
    ],
  };
  return (
    <Layout
      pageMeta={{
        title: `${header.sectionTitle} ${header.sectionHeader}`,
        author: "",
        image: header.sectionHeaderImage,
        description: header.sectionHeaderDescription,
      }}
    >
      <SectionHeader {...header} />

      <Section>
        <h5 className="text-primary text-h5">Primary areas of focus</h5>
        <div className="mt-5 grid gap-3 grid-cols-1 md:grid-cols-2">
          {focusArea.map((focus) => (
            <div className="mt-18">
              <h5 className="text-primary text-h6">{focus.title}</h5>
              <ol className="mt-3">
                {focus.description.map((value, key) => (
                  <AnclaList key={key}>{value}</AnclaList>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </Section>
      <SectionContactForm />
      <SectionContactSupport />
    </Layout>
  );
}

const focusArea = [
  {
    title: "Health Managment System (HMS)",
    description: ["Health Managment System"],
  },
];
